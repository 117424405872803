@import url('./entry-content.css');

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'WenQuanYi Bitmap Song 13px, Noto Sans SC'
    sans-serif;
  /* TODO: add Souce Sans Pro to fonts library https: //firewallcafe.com/wp-content/themes/fwc/fonts/source-sans-pro.css */
  /* font: 16px/24px 'Source Sans Pro', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header{width:100%;}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}

#search-spinner {
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M20 4v5h-.582m0 0a8.001 8.001 0 00-15.356 2m15.356-2H15M4 20v-5h.581m0 0a8.003 8.003 0 0015.357-2M4.581 15H9' /%3E%3C/svg%3E");
  animation: spin 1s infinite linear;
  position: absolute;
  left: 0.625rem;
  top: 0.75rem;
}

@font-face {
  font-family: WenQuanYi;
  src: url('./fonts/WenQuanYi Bitmap Song 13px.ttf');
}

@font-face {
  font-family: Noto Sans SC;
  src: url('./fonts/NotoSansSC-Regular.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chinese {
  font-family: WenQuanYi;
}

.chinese-body {
  font-family: noto-sans-tc, sans-serif;
  font-style: normal;
  font-weight: 100;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.navDrawer {
  z-index: 101;
  transition-duration: 500ms;
  top: 0;
  right: 0;
  transform: translate3d(100%, 0, 0);
  height: 100vh;
}

.font-bitmap-song {
  font-family: WenQuanYi;
}

/* #mc_embed_signup { clear:left; font:16px Helvetica,Arial,sans-serif; } */
                      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                        We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom tooltip styles */
.react-tooltip {
  background-color: white !important;
  color: #e60011 !important;
  border-radius: 4px !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  max-width: min(300px, calc(100vw - 48px)) !important;
  width: max-content !important;
  z-index: 1000 !important;
  border: 1px solid #e60011 !important;
  margin-left: 4px !important;
  white-space: normal !important;
  line-height: 1.5 !important;
}

@media (max-width: 480px) {
  .react-tooltip {
    font-size: 12px !important;
    padding: 6px 10px !important;
  }
}

input {
  background: #fff;
  color: #525865;
  border-radius: 2px;
  font-family: inherit;
  font-size: 1em;
  line-height: 1.45;
  outline: none;
  padding: 0.6em 1.45em 0.7em;
}

.main-inline-auto {
  max-inline-size: var(--width--content);
  margin-inline: auto;
}

:root {
  --space-m: clamp(1.5rem, 1.4348rem + 0.3261vw, 1.6875rem);
  /* --space-l: clamp(1.5rem, 1.4348rem + 0.3261vw, 1.6875rem); */
  
/* Layout */
  --width--content: 1080px;
  --width--content--medium: 1080px;
  --width--content--large: 1280px;

  --width--site-gutter: var(--space-m);
}